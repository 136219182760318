<template>
  <nav>
    <v-app-bar
      app
      clipped
      :style="
        userSettings != undefined && userSettings.toolbarTransparent
          ? 'background: transparent;box-shadow:none;'
          : ''
      "
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-spacer />
      <div v-if="status.loggedIn" class="d-flex justify-center align-center">
        <search />
        <DashboardTreeSelect v-if="permitted('Dashboard.View')" />
        <Notification v-if="permitted('Settings.GetSettings', '')" />
      </div>
    </v-app-bar>

    <v-navigation-drawer
      app
      v-model="drawer"
      v-bind:class="{
        darkSientific: darkTheme && userSettings.theme == 'Scientific',
        lightSientific: !darkTheme && userSettings.theme == 'Scientific',
        lightDrawer: !darkTheme && userSettings.theme != 'Scientific',
        darkDrawer: darkTheme && userSettings.theme != 'Scientific',
        primary: userSettings.theme != 'Scientific',
        'white--text': !darkTheme,
        'dark--text': darkTheme,
      }"
      :temporary="!userSettings.stickySideMenu"
    >
      <v-list>
        <v-list-item>
          <v-list-item-content>
            <v-img height="75px" width="200px" :src="logoPath" contain />
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-list-item
          v-for="link in viewableLinks"
          :key="link.text"
          router
          :to="link.route"
        >
          <v-list-item-action>
            <v-icon>{{ link.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ link.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item :href="this.SO_API_BASE_URL" target="_blank">
          <v-list-item-action>
            <v-icon>{{ apiLink.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ apiLink.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          :href="sensorShopLink.externalRoute"
          v-if="showShopLink == true"
          target="_blank"
        >
          <v-list-item-action>
            <v-icon>{{ sensorShopLink.icon }}</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>
              {{ sensorShopLink.text }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>
        <v-list-item>
          <v-list-item-content>
            <v-switch
              :label="$t('common.darkTheme')"
              v-model="darkTheme"
            ></v-switch>
          </v-list-item-content>
        </v-list-item>
        <v-divider></v-divider>
        <v-container class="mg-5" v-if="companies.length > 1">
          <company-switcher />
        </v-container>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import { Roles } from "@/_helpers/Role";
import Notification from "@/components/common/Notification";
import DashboardTreeSelect from "@/components/dashboard/DashboardTreeSelect";
import Search from "./common/Search.vue";
import CompanySwitcher from "./company/CompanySwitcher";

export default {
  name: "NavBar",

  props: ["dark"],

  sync: true,

  data() {
    return {
      drawer: false,
      darkTheme: this.$vuetify.theme.dark,
      company: "",
      showManualLink: process.env.VUE_APP_SO_MANUAL_LINK_BOOL,
      showShopLink: process.env.VUE_APP_SO_SHOP_LINK_BOOL,
      temporaryNavBar: false,
      appBarColor: "",
    };
  },

  methods: {
    ...mapActions({
      setDarkmode: "configuration/setDarkmode",
      getBaseUrl: "configuration/getBaseUrl",
    }),
    ...mapActions("companies", ["getAll", "setRootCompany", "getById"]),
    ...mapActions("users", ["setUserCompany"]),
    ...mapActions("configuration", ["getLicenseTerms"]),

    async submit() {
      var ci = this.company;
      await this.getById({ companyId: this.company });
      if (this.storeCompany.companyId != ci) return;

      this.setUserCompany({ company: this.storeCompany });
      if (this.isInsideDashboard) {
        this.$router.push("/dashboard");
      } else {
        this.$router.go();
      }
    },

    isSuperAdmin() {
      if (this.status.loggedIn) {
        if (
          this.permitted("Masquerade", "Companies.") ||
          this.permitted("SuperAdmin")
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
  },

  computed: {
    ...mapState("configuration", [
      "SO_API_BASE_URL",
      "userSettings",
      "license",
    ]),
    ...mapState("users", ["currentUser", "status", "userCompany"]),
    ...mapState("companies", {
      companies: "companies",
      storeCompany: "company",
    }),
    ...mapGetters("configuration", ["logoPath"]),

    Roles() {
      return Roles;
    },

    viewableLinks() {
      var links = [];
      if (this.status.loggedIn) {
        var authLinks = this.authLinks.filter((l) => {
          if (this.whiteLabel && l.whiteLabel) return false;

          return l.permit == null
            ? this.permitted(l.permitBackend, "")
            : this.permitted(l.permit);
        });

        links = links.concat(authLinks);
      } else {
        links = this.guestLinks;
      }

      return links;
    },

    guestLinks() {
      return [
        {
          icon: "mdi-home",
          text: this.$t("routes.home"),
          route: "/",
          permit: "ALL",
        },
        {
          icon: "mdi-login",
          text: this.$t("routes.login"),
          route: "/login",
          permit: "ALL",
        },
      ];
    },

    authLinks() {
      return [
        {
          icon: "mdi-view-dashboard",
          text: this.$t("routes.dashboard"),
          route: "/dashboard",
          permit: "Dashboard.View",
        },
        {
          icon: "mdi-domain",
          text: this.$t("routes.company"),
          route: "/company",
          permit: "Company.View",
        },

        {
          icon: "mdi-apps",
          text: this.$t("routes.application"),
          route: "/application",
          permit: "Application.View",
        },
        {
          icon: "mdi-connection",
          text: this.$t("routes.tag"),
          route: "/tag",
          permit: "Tag.View",
        },
        {
          icon: "mdi-access-point",
          text: this.$t("routes.gateway"),
          route: "/gateways",
          permit: "Gateways.View",
        },
        {
          icon: "mdi-alarm",
          text: this.$t("routes.alarm"),
          route: "/alarm",
          permit: "Alarm.View",
        },
        {
          icon: "mdi-language-javascript",
          text: this.$t("routes.script"),
          route: "/script",
          permit: "Script.View",
        },

        {
          icon: "mdi-file-export",
          text: this.$t("routes.report"),
          route: "/report",
          permit: "Report.Update",
        },
        {
          icon: "mdi-refresh-auto",
          text: this.$t("actions.title"),
          route: "/actions",
          permit: "Actions.View",
        },
        {
          icon: "mdi-account-plus",
          text: this.$t("routes.registerCompany"),
          route: "/register",
          permitBackend: "Users.CreateCompanyWithUser",
        },

        {
          icon: "mdi-cog",
          text: this.$t("routes.settings"),
          route: "/settings",
          permitBackend: "Settings.GetSettings",
        },

        {
          icon: "mdi-logout",
          text: this.$t("routes.logout"),
          route: "/logout",
          permit: "ALL",
        },
      ];
    },

    apiLink() {
      // Get env
      let apiUrl = this.SO_API_BASE_URL;
      return {
        icon: "mdi-api",
        text: this.$t("routes.apiRef"),
        externalRoute: apiUrl,
      };
    },

    manualLink() {
      return {
        icon: "mdi-file-document-box",
        text: this.$t("routes.manual"),
        externalRoute: "https://docs.sensor-online.se/",
      };
    },

    sensorShopLink() {
      return {
        icon: "mdi-store",
        text: this.$t("routes.shop"),
        externalRoute: "https://www.eyeo.se/IOT-AB-products.pdf",
      };
    },
  },

  async created() {
    await this.getBaseUrl();
    await this.getLicenseTerms();

    if (this.status.loggedIn) {
      await this.getAll();
      this.company = this.storeCompany.companyId;
      this.userSettings.stickySideMenu
        ? (this.drawer = true)
        : (this.drawer = false);
    }
  },

  watch: {
    darkTheme(v) {
      this.$vuetify.theme.dark = v;
      this.setDarkmode(v);
      return this.$vuetify.theme.dark;
    },

    storeCompany(v) {
      if (v == undefined) return;
      this.company = v.companyId;
    },
  },

  components: {
    Notification,
    DashboardTreeSelect,
    Search,
    CompanySwitcher,
  },
};
</script>
<style>
.lightDrawer {
  background: rgb(33, 150, 243);
  background: linear-gradient(
    0deg,
    rgba(33, 150, 243, 1) 0%,
    rgba(255, 255, 255, 1) 100%
  );
}

.darkDrawer {
  background: rgb(30, 63, 90);
  background: linear-gradient(
    0deg,
    rgba(30, 63, 90, 1) 0%,
    rgb(218, 227, 235) 100%
  );
}

.darkSientific {
  background: #383838 !important;
}

.lightSientific {
  background: #e9e9e9 !important;
}

.dark--text {
  color: rgba(0, 0, 0, 0.87) !important;
}
</style>

<style scoped>
.white--text {
  color: #fff !important;
}

.dark-background {
  background-color: rgba(0, 0, 0, 0.4);
}
</style>
