<template>
  <div>
    <h4>{{ userCompany?.name ?? "" }}</h4>
    <v-btn @click="() => (dialog = true)" block>{{
      $t("common.switchCompany")
    }}</v-btn>

    <v-dialog v-model="dialog" max-width="700px">
      <v-card>
        <v-card-title>{{ $t("common.switchCompany") }}</v-card-title>

        <v-card-text
          style="
            max-height: 400px !important;
            height: 400px;
            overflow-y: scroll;
          "
        >
          <v-text-field
            v-model="searchTerm"
            prepend-inner-icon="mdi-home-search"
            :label="$t('common.search')"
          />
          <div
            v-for="c in cs"
            :key="c.companyId"
            class="d-flex justify-space-between pa-4 mb-2 rounded-lg company-row"
          >
            <div>
              <h2>{{ c.name }}</h2>
              <span>{{ c.companyId }} - {{ c.orgNumber }}</span>
            </div>
            <div>
              <v-btn
                light
                @click="select(c.companyId)"
                :disabled="userCompany.companyId == c.companyId"
              >
                {{
                  userCompany.companyId == c.companyId
                    ? $t("common.selected")
                    : $t("common.select")
                }}
              </v-btn>
            </div>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />
          <v-btn @click="() => (dialog = false)" text color="grey">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "CompanySwitcher",

  data() {
    return {
      dialog: false,
      searchTerm: "",
    };
  },

  computed: {
    ...mapState("companies", ["companies", "company"]),
    ...mapState("users", ["userCompany"]),

    cs() {
      let temp = this.companies.slice(0, this.companies.length);
      if (temp.length <= 1) return temp;

      let idx = this.companies.findIndex(
        (d) => d?.companyId == this.userCompany.companyId
      );
      if (idx != undefined) {
        var cmp = temp[0];
        temp[0] = temp[idx];
        temp[idx] = cmp;
      }

      return temp.filter((d) =>
        d?.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
  },

  methods: {
    ...mapActions("companies", ["getAll", "getById"]),
    ...mapActions("users", ["setUserCompany", "refreshUserData"]),
    ...mapActions("configuration", ["getCompanySettings"]),

    async select(companyId) {
      await this.getById({ companyId: companyId });
      if (this.company.companyId != companyId) return;

      this.setUserCompany({ company: this.company });
      await this.getCompanySettings();
      this.refreshUserData();
    },
  },

  async created() {
    await this.getAll();
  },
};
</script>

<style>
.company-row {
  border: 1px solid #cfcfcf;
  padding: 1rem;
  background-color: #e6e6e6 !important;
  color: black;
}
</style>
