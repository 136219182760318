import ContactRepository from "@/api/repositories/contactRepository";

export const contacts = {
  namespaced: true,
  state: {
    contacts: [],
  },

  actions: {
    async getContacts({ commit }) {
      await ContactRepository.getContacts()
        .then((d) => commit("SET_CONTACTS", d.data))
        .catch((e) => {
          commit("SET_CONTACTS", []);
          console.error("Error fetching the contacts for company", e);
        });
    },

    async createContact(_, payload) {
      return await ContactRepository.create(payload)
        .then(() => true)
        .catch(() => false);
    },

    async deleteContact(_, actionId) {
      return await ContactRepository.delete(actionId)
        .then(() => true)
        .catch(() => false);
    },
  },

  mutations: {
    SET_CONTACTS(state, payload) {
      state.contacts = payload;
    },
  },

  getters: {
    getContactById: (state) => (id) => {
      return state.contacts.find((d) => d.contactId === id);
    },
  },
};
