import Repository from "@/api/Repository";
import { apiConfig, company } from "@/_helpers/apiHelper";

const resource = "/action";

export default {
  getActions() {
    return Repository.get(`${resource}/${company()}`, apiConfig());
  },

  create(payload) {
    return Repository.post(`${resource}/${company()}`, payload, apiConfig());
  },

  update(actionId, payload) {
    return Repository.put(`${resource}/${actionId}`, payload, apiConfig());
  },

  delete(actionId) {
    return Repository.delete(`${resource}/${actionId}`, apiConfig());
  },

  features() {
    return Repository.get(`${resource}/features`, apiConfig());
  },

  eventTypes() {
    return Repository.get(`${resource}/event-types`, apiConfig());
  },

  resetCooldown(actionId) {
    return Repository.put(
      `${resource}/cooldown/reset/${actionId}`,
      {},
      apiConfig()
    );
  },

  resetResourceCooldown(actionResourceId) {
    return Repository.put(
      `${resource}/cooldown/reset/resource/${actionResourceId}`,
      {},
      apiConfig()
    );
  },
};
