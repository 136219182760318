import Repository from "@/api/Repository";
import { apiConfig, company } from "@/_helpers/apiHelper";

const resource = "/contact";

export default {
  getContacts() {
    return Repository.get(`${resource}/${company()}`, apiConfig());
  },

  create(payload) {
    return Repository.post(`${resource}/${company()}`, payload, apiConfig());
  },

  delete(contactId) {
    return Repository.delete(`${resource}/${contactId}`, apiConfig());
  },
};
