import Repository from "../Repository";
import { apiConfig } from "../../_helpers/apiHelper";
import store from "../../stores/store";

const resource = "/tagJournal";

export default {
  getJournalsForTag(tagId, sortorder = "desc") {
    if (tagId == undefined) return [];

    let user = store.state?.users?.currentUser;
    if (!user) return [];

    return Repository.get(
      `${resource}/tag/${tagId}?sortOrder=${sortorder}`,
      apiConfig()
    )
      .then((d) => d.data)
      .catch((d) => console.error(d));
  },

  getJournalByTagJournalId(tagJournalId) {
    if (tagJournalId == undefined) return [];

    let user = store.state?.users?.currentUser;
    if (!user) return [];

    return Repository.get(`${resource}/${tagJournalId}`, apiConfig());
  },

  createJournal(payload) {
    return Repository.post(
      `${resource}/${payload.deveui}`,
      payload,
      apiConfig()
    );
  },

  updateJournal(tagJournalId, payload) {
    return Repository.put(
      `${resource}/update/${tagJournalId}`,
      payload,
      apiConfig()
    );
  },

  deleteJournal(tagJournalId) {
    return Repository.delete(`${resource}/delete/${tagJournalId}`, apiConfig());
  },
};
