import { RepositoryFactory } from "../../api/RepositoryFactory";
import i18n from "@/plugins/i18n";
// import ApiHelper from "@/_helpers/apiHelper.js";

const TagJournalRepository = RepositoryFactory.get("tagJournal");

export const tagJournal = {
  namespaced: true,
  state: {
    currentJournal: {},
    journals: [],
    status: {
      loading: false,
    },
    apiErrors: [],
  },

  actions: {
    async getJournalsForTag({ dispatch, commit, rootState }, tagId) {
      commit("LOADING", true);

      return await TagJournalRepository.getJournalsForTag(tagId)
        .then((journals) => {
          commit("SET_JOURNALS", journals.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
          return [];
        });
    },

    async getJournalByTagJournalId(
      { dispatch, commit, rootState },
      tagJournalId
    ) {
      return await TagJournalRepository.getJournalByTagJournalId(tagJournalId)
        .then((journals) => {
          commit("SET_JOURNALS", journals.data);
          commit("LOADING", false);
        })
        .catch((res) => {
          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
          dispatch("alert/error", i18n.t("common.error"), { root: true });
          commit("LOADING", false);
          return [];
        });
    },

    async createJournal({ dispatch, commit, rootState }, payload) {
      commit("LOADING", true);

      return TagJournalRepository.createJournal(payload)
        .then((journal) => {
          commit("SET_CURRENT_JOURNAL", journal.data);
          commit("CLEAR_ERRORS");

          // Return the journal data
          return journal.data;
        })
        .catch((res) => {
          commit("SET_ERRORS", res.response.data.errors);

          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
          commit("LOADING", false);
          return [];
        });
    },

    async updateJournal(
      { dispatch, commit, rootState },
      { tagJournalId, payload }
    ) {
      commit("LOADING", true);

      return TagJournalRepository.updateJournal(tagJournalId, payload)
        .then((journal) => {
          commit("SET_CURRENT_JOURNAL", journal.data);
          commit("CLEAR_ERRORS");

          // Return the journal data
          return journal.data;
        })
        .catch((res) => {
          commit("SET_ERRORS", res.response.data.errors);

          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
          commit("LOADING", false);
          return [];
        });
    },

    async deleteJournal({ dispatch, commit, rootState }, tagJournalId) {
      return TagJournalRepository.deleteJournal(tagJournalId)
        .then(() => {
          // Update local state to remove the deleted journal
          commit("REMOVE_JOURNAL_ENTRY", tagJournalId);

          dispatch("alert/success", i18n.t("common.deleted"), { root: true });
          return true;
        })
        .catch((res) => {
          if (res.response.status === 401) {
            return dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            ).then(() => {
              // Continue with the error handling after re-authentication
              return false;
            });
          } else {
            dispatch("alert/error", i18n.t("common.error"), { root: true });
            return false;
          }
        });
    },

    resetState({ commit }) {
      commit("RESET_STATE");
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },

    SET_JOURNALS(state, journals) {
      state.journals = journals;
    },

    SET_CURRENT_JOURNAL(state, journal) {
      state.currentJournal = journal;
    },

    SET_ERRORS(state, errors) {
      state.apiErrors = errors;
    },

    CLEAR_ERRORS(state) {
      state.apiErrors = [];
    },

    RESET_STATE(state) {
      state.currentJournal = {};
      state.journals = [];
      state.status.loading = false;
    },

    REMOVE_JOURNAL_ENTRY(state, tagJournalId) {
      state.journals = state.journals.filter(
        (journal) => journal.tagJournalId !== tagJournalId
      );
    },
  },
};
