import Repository from "../Repository";
import { company, getAuthHeader } from "../../_helpers/apiHelper";

const resource = "/report";

export default {
  getReport(user, reportId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/${reportId}`, config);
  },

  getReports(user, companyId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.get(`${resource}/company/${companyId}`, config);
  },

  create(user, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.post(`${resource}/${company()}`, payload, config);
  },

  update(user, reportId, payload) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.put(`${resource}/${reportId}`, payload, config);
  },

  delete(user, reportId) {
    var config = {
      headers: getAuthHeader(user),
    };

    return Repository.delete(`${resource}/${reportId}`, config);
  },

  multiExport(user, payload, companyId = null) {
    var config = {
      headers: getAuthHeader(user),
      responseType: "blob",
    };

    return Repository.post(
      `${resource}/multiexport/${companyId}`,
      payload,
      config
    );
  },
};
