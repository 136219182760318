import { RepositoryFactory } from "../../api/RepositoryFactory";

const TagJournalFileRepository = RepositoryFactory.get("tagJournalFile");

export const tagJournalFile = {
  namespaced: true,
  state: {
    files: [],
    status: {
      loading: false,
    },
    apiErrors: [],
  },

  actions: {
    async uploadJournalFile({ dispatch, commit, rootState }, payload) {
      commit("LOADING", true);

      return await TagJournalFileRepository.uploadJournalFile(payload)
        .then((response) => {
          commit("LOADING", false);

          // Return the response data
          return response.data;
        })
        .catch((res) => {
          commit("LOADING", false);

          if (res.response.status === 401) {
            dispatch(
              "users/login",
              {
                email: rootState.users.credentials.email,
                password: rootState.users.credentials.password,
              },
              { root: true }
            );
          }
          commit("LOADING", false);
          return [];
        });
    },
  },

  mutations: {
    LOADING(state, status) {
      state.status.loading = status;
    },
  },
};
