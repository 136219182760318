import Repository from "../Repository";
import { apiConfig } from "../../_helpers/apiHelper";

const resource = "/tagJournalFile";

export default {
  uploadJournalFile(payload) {
    return Repository.post(`${resource}/upload`, payload, apiConfig());
  },
};
