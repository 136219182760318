export default {
  routes: {
    home: "Hjem",
    login: "Log ind",
    register: "Registrer",
    registerCompany: "Ny virksomhed",
    logout: "Log ud",

    company: "Virksomhed",
    dashboard: "Dashboard",
    application: "Applikationer",
    alarm: "Alarmhåndtering",

    user: "Brugere",
    tagdata: "Data",
    tag: "Noder",
    script: "Script Engine",
    report: "Rapporter",
    contact: "Kontakt",
    apiRef: "API-Dokumentation",
    shop: "Køb noder",
    manual: "Manual",
    userEdit: "Konto",
    settings: "Indstillinger",
    signalTest: "Felttest",
    gateway: "Gateways",
    news: "Nyheder",
    roles: "Roller",

    superadmin: "Admin",
  },

  camOnline: {
    servers: "Cam-Online Servere",
    couldNotFetchServers: "Kunne ikke hente CamOnline Servere",
    couldNotCreateServer: "Noget gik galt ved oprettelse af CamOnline Server",
    couldNotDeleteServer: "Noget gik galt ved sletning af CamOnline Server",

    serverSelect: "Vælg Cam-Online Server",
    streamSelect: "Vælg et kamera",
    createDialogTitle: "Tilføj en ny server",
    tabTitle: "Cam-Online",
    fields: {
      url: "URL (f.eks. https://portal.cam-online.se)",
      accessToken: "Adgangstoken",
      isGlobal: "Global Server",
    },
  },
  roles: {
    toolbarTitle: "Roller",
    permissionCounts: "Tilladelser",

    missingRecommendedTitle:
      "Det ser ud til, at du mangler nogle anbefalede tilladelser. Ønsker du at tilføje dem?",
    missingRecommendedButton: "Klik her!",

    pickPermissionsTitle: "Vælg tilladelser for denne rolle",
    pickPermissionsDesc:
      "Når du vælger tilladelser, kan du støde på <b class='yellow--text text--darken-3'>Orange</b> tilladelser. Disse tilladelser anbefales for at få den grundlæggende funktionalitet til at fungere. Du kan vælge en af standardrollerne nedenfor for at bruge dem som skabelon.",

    layerTypes: {
      0: "Frontend-essentials",
      1: "Frontend",
      2: "Backend",
    },

    fields: {
      name: "Rollenavn",
      permissionCount: "Antal tilladelser",
      isDefault: "Er standard",
      isSuper: "Er administrator",
    },
  },

  eventhub: {
    waiting: "Venter på nye begivenheder...",

    events: {
      data: "Ny data",
      endpoint: "Endpoint-begivenhed",
    },
  },

  downlinks: {
    editorTitle: "Downlinks",

    validation: {
      port: "Porten skal være større end 0",
    },
  },

  conditionTree: {
    selectValueTypeTitle: "Vælg værditype",
    selectType: "Vælg type",
    selectValue: "Vælg værdi",
    chooseSensor: "Vælg sensor",

    textInputPlaceholder: "Tekstværdi",
    numberInputPlaceholder: "Talværdi",

    types: {
      0: "Sensorværdi",
      1: "Tekst",
      2: "Tal",
      3: "Tid",
      4: "Ingen værdi",
    },

    ops: {
      greater: "Større end",
      less: "Mindre end",
      equal: "Lig med",
      greaterOrEqual: "Større eller lig med",
      lessOrEqual: "Mindre eller lig med",
      notEqual: "Ikke",
    },
  },

  accessToken: {
    title: "Adgangstokens",
    newToken: "Opret en token",
    cardNewTitle: "Ny token",
    cardTitle: "Adgangstoken",
    fields: {
      id: "Id",
      label: "Titel",
      token: "Token",
      isCompany: "Firma-token",
    },
  },

  news: {
    cannotGetNews: "Kunne ikke hente nyheder fra portalen.",
  },

  gateway: {
    toolbar: "Gateways",
    toolbarTitle: "Gateways",
    bestGw: "Bedste gateway",

    pickLocationButton: "Indstil breddegrad og længdegrad",
    fields: {
      name: "Navn",
      macAddress: "GatewayEUI",
      latitude: "Breddegrad",
      longitude: "Længdegrad",
      createdAt: "Oprettet",
    },
  },

  signalTest: {
    toolbarTitle: "Feltests",
    startTest: "Start en ny test",
    endTest: "Afslut test",
    peekTest: "Se på test",
    createToolbar: "Start en ny test",
    rawData: "Rådata",
    updateTimer: "Opdateringstimer",

    fields: {
      testId: "Test-id",
      description: "Beskrivelse",
      startTest: "Start",
      endTest: "Afslut",
      active: "Aktiv",

      avgSnr: "Gennemsnitlig SNR",
      avgRssi: "Gennemsnitlig RSSI",

      data: {
        rssi: "RSSI",
        snr: "SNR",
        lat: "BREDDEGRAD",
        lng: "LENGDEGRAD",
        createdAt: "TID",
      },
    },

    tab: {
      map: "KORT",
      data: "STATISTIK",
    },
  },

  trend: {
    res: {
      week: "Opløsning: Uge",
      day: "Opløsning: Dag",
      hour: "Opløsning: Time",
      five: "Opløsning: 5 Timer",
    },
  },

  notifications: {
    clearNotifications: "Marker alle som læst",
    noNotifications: "Du er opdateret.",
  },

  landing: {
    slogan: "VISUALISERING GJORT LET",
    or: "ELLER",
    key: {
      title: "Nøglefunktioner",
      1: "Nodeadministration",
      2: "Visualisering",
      3: "Tilpassede dashboards",
      4: "Alarmstyring",
      5: "Personalestyring",
      6: "Scriptmotor",

      description: {
        1: "Administrer sensorer til håndtering af data",
        2: "Få dine sensordata visualiseret, så du kan observere dataene på den ønskede måde",
        3: "Opret dit eget dashboard specialiseret til DIT anvendelsesområde",
        4: "Alarmstyring til kritiske anvendelsestilfælde",
        5: "Til styring af dit firmas personale",
        6: "Avancerede brugere kan bruge scriptmotoren til at bearbejde data",
      },
    },

    usecases: {
      title: "Anvendelsesområder",
      1: "Miljøsensorer",
      2: "Elektricitetssensorer",
      3: "Bevægelsessensorer",
      4: "Temperatursensorer",

      description: {
        1: "Perfekt til smarte landbrugsløsninger som Ljusgarda, der bruger portalen. Alarmfunktionen i SENSORONLINE er nøglen til at holde dine aktiver sikre.",
        12: "Læs mere om Ljusgarda",
        2: "Mål strømmen til gadelamper i din by. Brug scriptmotoren til at forstå de indsamlede data.",
        3: "Mål, hvor mange personer eller biler der forlader eller indtaster din facilitet.",
        4: "Selvfølgelig er temperaturmålinger en vigtig del af vores platform. Mange virksomheder bruger og holder øje med deres aktivers temperaturer.",
      },
    },

    help: {
      title: "Kontakt os",

      sensors: {
        title: "Har brug for nye noder?",
        description:
          "Hvis du har brug for hjælp til at løse et problem, om det så er hvilken sensor du skal bruge eller hvordan du kan visualisere dine data på en bedre måde. Tøv ikke med at kontakte os, så vi kan gøre dit liv lettere. \n Du kan se vores portefølje af ",
        store: "noder, som vi sælger her hos KK Partner A/S.",
      },

      api: {
        title: "API til din bekvemmelighed",
        description:
          "Du kan selvfølgelig få eller indsætte data fra dine andre applikationer til platformen. Se vores API-slutpunkter ",
      },

      contact: {
        title: "Er du interesseret? Kontakt os!",
        siencepark: "Skövde Science Park",
        address: "Kaplansgatan 16B",
        postal: "521 32 Skövde",
        country: "Sverige",
        phoneTitle: "Telefon",
        phonenumber: "+46 (0) 500 600 022",
        phonenumber2: "+46 (0) 733 165 100",
        emailTitle: "Email",
        email: "info@sensor-online.se",
      },
    },
  },

  imageMap: {
    chooseTag: "Vælg type markør",
  },

  log: {
    type: {
      sms: "SMS",
      app: "Applikation",
      warning: "Advarsel",
      error: "Fejl",
      userevent: "Brugerbegivenhed",
      login: "Login",
    },

    logTable: {
      title: "Log",
    },

    activeAlarms: {
      title: "Aktive alarmer",
    },

    activeUsersTable: {
      title: "Logget ind brugere",
    },

    fields: {
      type: "Type",
      user: "Bruger",
      message: "Besked",
      createdAt: "Oprettet",

      status: "Status",
      lastAction: "Begivenhed",
      lastSeen: "Tidsstempel",

      alarm: {
        name: "Navn",
        value: "Værdi",
        messageSent: "Sendt besked",
      },
    },
  },
  common: {
    yes: "Ja",
    no: "Nej",
    on: "Tændt",
    off: "Slukket",
    timestamp: "Tidsstempel",
    lang: "Sprog",
    error: "Der opstod en fejl, prøv igen senere",
    edit: "Rediger",
    delete: "Slet",
    reset: "Nulstil",
    purge: "Sletning",
    submit: "Send",
    create: "Opret",
    update: "Opdater",
    get: "Få",
    run: "Kør",
    address: "Adresse",
    link: "Link",
    height: "Højde",
    width: "Bredde",
    new: "Ny",
    value: "Værdi",
    newValue: "Ny værdi",
    nothingToSee: "Intet at se her",
    next: "Næste",
    label: "Mærkat",
    editLabel: "Rediger mærkat",
    explanation: "Forklaring",
    clear: "Ryd",
    never: "Aldrig",
    upload: "Uploade",
    click: "Klik",
    required: "Kræves",

    previousStep: "Tilbage",
    hours: "Timer",
    mintues: "Minutter",

    search: "Søg",
    close: "Luk",
    cancel: "Annuller",
    success: "Succesfuldt!",

    login: "Login",
    register: "Tilmeld",
    email: "Email",
    password: "Kodeord",
    confirmPassword: "Bekræft kodeord",
    newPassword: "Nyt kodeord",
    forgotPassword: "Glemt kodeord",

    createdAt: "Oprettet",
    updatedAt: "Opdateret",

    save: "Gem",
    show: "Vis",
    updated: "Opdateret",
    created: "Oprettet",
    deleted: "Slettet",
    back: "Tilbage",
    send: "Send",
    refresh: "Opdater",

    preview: "Forhåndsvisning",
    previewSpacing: "Plads til andre moduler",

    saveColor: "Gem farve",
    alarmColorPrecedence:
      "Alarmfarver vil altid have forrang over manuelt valgte farver. Tving overordning i attributter, hvis dette ikke er det ønskede.",

    add: "Tilføj",

    settings: "Indstillinger",

    noTagOrAppChoosen: "Node og/eller applikation mangler",
    noDataFound: "Ingen data fundet",
    darkTheme: "Mørkt tema",

    active: "Aktiv",
    notActive: "Inaktiv",

    sent: "Sendt",
    notSent: "Ikke sendt",

    loginOrRegister: "Log ind eller registrer",

    copy: "Kopier",
    verify: "Er du sikker på, at du vil fortsætte?",
    verifyDelete: "Er du sikker på, at du vil fjerne denne ressource?",

    disagree: "Nej",
    accept: "Ja",

    or: "Eller",

    registerCompany: "Opret nyt firma",

    dashboard: "Gå til oversigt",
    switchCompany: "Skift firma",

    inactiveSensors: "Inaktive sensorer",
    activeSensors: "Aktive sensorer",
    sensorStatus: "Node-status",
    sensorName: "Node-navn",

    updatesPending: "Ændringer afventer opdatering",
    pickLocationConfirm: "Ønsker du at gemme positionen for denne ressource?",

    toFewBytes: "For få bytes",
    byteInputRequired: "Du skal udfylde dette felt",

    getMorePages: "Indlæs flere",

    searchType: {
      TAG: "Noder",
      ALARM: "Alarmer",
      APPLICATION: "Applikationer",
      DASHBOARD: "Oversigter",
      GATEWAY: "Gateways",
      SCRIPT: "Scripts",
    },

    time: {
      day: "Dag",
      hour: "Time",
      quarterHour: "Kvart time",
      minute: "Minut",
    },

    days: {
      monday: "Mandag",
      tuesday: "Tirsdag",
      wednesday: "Onsdag",
      thursday: "Torsdag",
      friday: "Fredag",
      saturday: "Lørdag",
      sunday: "Søndag",
    },

    dateRange: {
      0: "Seneste time",
      1: "Seneste 5 timer",
      2: "Seneste 24 timer",
      3: "Seneste 7 dage",
      4: "Seneste 30 dage",
    },

    staticinformation: {
      datapoints: "Datapunkter seneste 24 timer",
      inactiveSensors: "Inaktive sensorer",
      activeAlarms: "Aktive alarmer",
      datapointsLastWeek: "Datapunkter sidste uge pr. dag",
      weekday: "Ugedag",
    },

    errors: {
      getDecoders: "Kunne ikke hente dekodere",
      backendConnection: "Der kunne ikke oprettes forbindelse til backend.",
    },

    actions: {
      createCategory: "Opret kategori",
      editCategory: "Rediger kategori",
      remove: "Fjern",

      createDashboard: "Opret oversigt",
      copyDashboard: "Kopier oversigt",
    },

    iconSelector: {
      selectIcon: "Vælg ikon",
      searchPlaceholder: "Søg efter ikoner",
    },
  },

  settings: {
    title: "Indstillinger",
    userSettings: "Brugerindstillinger",
    themes: "Temaer",

    company: {
      title: "Firma indstillinger",
    },

    data: {
      toolbarTransparancy: "Gennemsigtig værktøjslinje",
      stickySideMenu: "Fast side-menu",
    },

    global: {
      name: "Generelle indstillinger",
      branding: "Branding",
      powered_by: "Drevet af",
      SMS_PROVIDER: "SMS-udbyder",
      SMS_IP1_FROM: "IP1 Afsendernavn",
      SMS_IP1_USERNAME: "IP1 Brugernavn",
      SMS_IP1_PASSWORD: "IP1 Adgangskode",
      SMS_TWILIO_ACCOUNTSID: "Twilio AccountSID",
      SMS_TWILIO_ACCESSTOKEN: "Twilio AccessToken",
      SMS_TWILIO_NUMBER: "Twilio-nummer",
    },
  },

  companysetting: {
    field: {
      smsprovider: "SMS-udbyder",
      alarmTitle: "Alarm",
      nodeTitle: "Node",
      nodeAlias: "Node Alias",

      inactivityAlarmContact: "E-mail til inaktivitetsalarm",

      hint: {
        inactivityAlarmContact:
          "E-mail-adresse til modtagelse af alarmer, når enheder har været inaktive i mindst 6 timer",
        nodeAlias: "Dette hjælper med at foreslå og oprette nodenavnet",
      },

      ip1: {
        username: "IP1-brugernavn",
        password: "IP1-adgangstoken / adgangskode",
        from: "Afsendernavn",
        hint: {
          from: "Dette er et navn, der vil blive brugt, når SensorOnline sender SMS'er",
          username:
            "Hvis du vil finde dit brugernavn, skal du besøge dokumentationen: https://docs.sensor-online.se",
          password:
            "Hvis du vil finde din adgangskode, skal du besøge dokumentationen: https://docs.sensor-online.se",
        },
      },

      twilio: {
        number: "Twilio-nummer",
        accountSid: "Twilio AccountSID",
        accessToken: "Twilio AccessToken",
        hint: {
          number:
            "Et nummer, du ejer på Twilio, som du kan sende beskeder med.",
          accountSid:
            "Du kan finde flere oplysninger om, hvor du finder AccountSID, i vores dokumentation: https://docs.sensor-online.se",
          accessToken:
            "Du kan finde flere oplysninger om, hvor du finder AccessToken, i vores dokumentation: https://docs.sensor-online.se",
        },
      },
    },
  },

  map: {
    setPosFormTitle: "Vælg sensor for at ændre position",
  },

  dashboard: {
    name: "Dashboards",
    selectDashboard: "Dashboards",
    createTitleClean: "Opret nyt dashboard",
    copyDeleteTitle: "Slet eller kopier dashboard",
    createTitle: "Eller opret et nyt dashboard",
    chooseTitle: "Vælg dashboard",
    changeTitle: "Skift dashboard",
    navigationTitle: "Navigationsmenu",

    fields: { name: "Navn" },
    nameReq: "Dette felt skal udfyldes",

    categories: {
      create: "Opret kategori",
      title: "Titel",
      icon: "Ikon",
    },

    copy: {
      tooltip: "Kopier widget",
    },

    public: {
      title: "Offentlige dashboards",
      create: "Opret et offentligt dashboard",
      createNew: "Opret et nyt offentligt dashboard",
      copiedSuccessful: "Link kopieret til udklipsholderen",
      copiedUnsuccessful: "Kunne ikke kopiere link {link}",
      URL: "Dashboard-URL",
      yes: "Ja",
      no: "Nej",
      remove: "Fjern",

      fields: {
        host: "Vært",
        expiresAt: "Gyldig indtil",
        expired: "Aktiv",
        copyAction: "Link",
        dashboardName: "Dashbaord",
      },
    },
  },
  module: {
    create: "Opret en ny enhed",
    edit: "Rediger enhed",
    metas: "Attributter",

    chart: {
      types: {
        line: "Linje",
        area: "Område",
        bar: "Søjle",
        scatter: "Spredning",
        vertical: "Vertikal",
      },
    },

    mapMarkers: {
      node: "Peg med nodens info",
      sensor: "Tekstetiket med seneste sensorværdi",
    },

    meta: {
      label: "Attributter",
      save: "Gem attributter",
      opacity: "Gennemsigtighed",

      paletteToModuleMode: "Skift modulfarver",
      paletteToCardMode: "Skift kortfarver",

      valueMatch: "Attribut 1",
      value: "Attribut 2",
      key: "Sensor",
      icon: "Ikon",

      styles: {
        text: {
          textcolor: "Tekstfarve",
          background: "Baggrund - Indhold",
          titleBackground: "Baggrund - Titel",
        },

        single: {
          textcolor: "Tekstfarve",
          background: "Baggrund - Indhold",
          titleBackground: "Baggrund - Titel",
          sparkline: "Linjediagram",
          sparklineLabel: "Linjediagram mærkat",
        },

        color: {
          cardBackground: "Kortbaggrund",
          titleBackground: "Titelbaggrund",
          titleText: "Titeltekst",
          valueBackground: "Værdi-baggrund",
          valueText: "Værdi-tekst",
        },
      },

      fontStyleTypes: {
        title: "Titel",
        value: "Værdi",
        content: "Indhold",
      },

      keys: {
        value_match: "Værdi-navn",
        icon: "Suffiksikon",
        sufix: "Suffiks",
        prefix: "Præfiks",
        deveui: "Node",
        maxheight: "Maksimal højde",
        scriptid: "Script-ID",
        target: "Mål",
        tagkey: "Sensor",
        tagkey_x: "Sensor X",
        tagkey_y: "Sensor Y",
        tagkey_x_max: "Sensor X Maks.",
        tagkey_y_max: "Sensor X Min.",
        forceColors: "Tving farver",
        decimal_rounding: "Decimal afrunding",
        dashboard_id: "Dashboard-ID",
        sync_y: "Synkroniser Y-akse",
        zoom_slider: "Zoomslider",
        digital: "Firkantsbølge",
        alarm_id: "Alarmgrænser",
        so_chart_type: "Diagramtype",
        so_ignore_body: "Kun titel",
        min_value: "Minimumsværdi",
        max_value: "Maksimumsværdi",
        axis_label: "Akselmærkat",
        default_timespan: "Standard tidsinterval",
        font_size: "Skriftstørrelse",
        hide_trend_icon: "Skjul trendikon",
        horizontel_single_title: "Vandret titel",
        so_hide_node_info: "Skjul nodens information",
        font_style_large: "Skrifttype",
        so_single_sparkline: "Linjediagram",
        gauge_180: "180° måler",
        button_label: "Knapmærkat",
        button_label_on: "Mærkat for TÆND-knap",
        button_label_off: "Mærkat for SLUK-knap",
        hide_title: "Skjul titel",
      },

      fields: {
        value_match: "Værdi",
        name: "Navn",
        icon: "Suffiksikon",
        sufix: "Suffiks",
        prefix: "Præfiks",
        deveui: "Node",
        maxheight: "Maksimal højde (f.eks. 300 for 300px)",
        scriptid: "Script-ID",
        target: "Mål",
        tagkey: "Sensor",
        tagkey_x: "Sensor X",
        tagkey_y: "Sensor Y",
        tagkey_x_max: "Sensor X Maks.",
        tagkey_y_max: "Sensor X Min.",
        forceColors: "Tving farver",
        decimal_rounding: "Decimaler",
        dashboard_id: "Dashboard-ID",
        sync_y: "Synkroniser Y-akse",
        zoom_slider: "Vis zoomslider",
        digital: "Firkantsbølge",
        alarm_id: "Alarmgrænser",
        chart_type: "Diagramtype",
        ignoreBody: "Kun titel",
        min_value: "Minimumsværdi",
        max_value: "Maksimumsværdi",
        axis_label: "Akselmærkat",
        default_timespan: "Standard tidsinterval",
        font_style_large: {
          type: "Type",
          size: "Størrelse",
          weight: "Vægt",
        },
      },

      desc: {
        forceColors:
          "Tving de valgte farver til at tage forrang over alarmfarver",
      },
    },

    fields: {
      title: "Titel",
      types: "Type",
      content: "Indhold",
      sm: "Størrelse på lille skærm",
      md: "Størrelse på mellemstor skærm",
      min: "Min. værdi",
      max: "Maks. værdi",
      image: "Billede",
      url: "URL",
      sync_chart: `Synkroniser alle serier på X-aksen
når dataformaterne er meget forskellige)`,
    },

    enum: {
      size: { xs: "EKSTRA LILLE", sm: "LILLE", md: "MEDIUM", lg: "STOR" },
      types: {
        0: "Diagram",
        1: "Måler",
        2: "Tekst",
        3: "Billede",
        4: "Google Kort",
        5: "Virksomhedsoplysninger",
        6: "IFrame",
        7: "SMHI",
        8: "Seneste værdi",
        9: "Rå tabel",
        10: "Diagram",
        11: "Billedkort",
        12: "Funktionsknap",
        13: "Node-Info",
        14: "Trenddiagram",
        15: "Node-overblik",
        16: "360° Vippevisualisering",
        17: "Fyldniveauindikator",
        18: "Værdiindstillingslåse",
        19: "Kamera",
      },
    },

    functionButtonTypes: {
      script: "Scriptudførelse",
      input: "Direkte værdiindtastning",
      navigation: "Dashboard Link",
      downlink: "Downlinks",
      onoffSwitch: "TÆND/SLUK-knap",
    },
  },

  weather: {
    title: "SMHI Vejrudsigt",
    icon: "Vejr",
    temp: "Temperatur",
    windDir: "Vindretning",
    windSpeed: "Vindhastighed",
    humidity: "Fugtighed",
    thunder: "Torden",
    date: "Dato",
    precipitation: "Nedbør",
  },

  contact: {
    titel: "Kontakt os",
    descriptionTitle: "Sensor-Online",
    description:
      "Vi ved, at det kan være svært at komme i gang. Tøv ikke med at kontakte os, og vi vil gerne hjælpe med at besvare dine spørgsmål.",
    emailTitle: "Email",
    email: "info@sensor-online.se",
    phoneTitle: "Telefon",
    phoneOne: "+46 (0) 500 6000 22",
    phoneTwo: "+46 (0) 733 165 100",
    locationSiencepark: "Skövde Science Park",
    locationStreet: "Kaplansgatan 16B",
    locationPostalcode: "521 32 Skövde",
    locationCountry: "Sverige",
  },

  company: {
    name: "Firmanavn",
    orgNumber: "Moms nummer",

    companyInfo: "Firmaoplysninger",

    addUser: "Tilføj ny bruger",

    invite: "Inviter ny bruger",
    inviteSent: "Invitation sendt",
    inviteFailed:
      "Kunne ikke finde brugeren. Sørg for, at brugeren er registreret!",

    shareApp: "Del applikation",

    fields: {
      name: "Firmanavn",
      orgNumber: "Organisationsnummer",
      postalAdress: "Postadresse",
      deliveryAdress: "Leveringsadresse",
      contactPhonenumber: "Kontakt telefonnummer",
      invoiceAdress: "Email for kontakt",
      refrenceNmuber: "Reference nummer",
      image: "Firma logo",
    },

    validation: {
      nameRequired: "Du skal indtaste et firmanavn",
      nameMax: "Firmanavnet må ikke være mere end 255 tegn",

      orgNumberRequired: "Du skal indtaste dit CVR-nummer",
      orgNumberFormatError: "Forkert format for CVR-nummer",

      emailRequired: "Du skal indtaste en email-adresse",
      emailFormatError: "Indtast en gyldig email-adresse",

      passwordMissmatch: "Adgangskoderne stemmer ikke overens",
    },
  },

  report: {
    title: "Rapporter",
    refrenceDateDesc:
      "Referencedagen er den dag, der refereres til ved afsendelse af en rapport. Hvis intervallet er månedligt, skal du vælge den dag, den sendes (f.eks. den 1. i hver måned). Eller ugentligt (hvor dagen er mandag til fredag, når rapporten skal sendes ud).",
    create: "Opret rapportkørsel",
    update: "Opdater rapportkørsel",
    exportDownload: "Download rapport",
    export: "Eksporter",
    downloadFailed: "Rapporten kunne ikke hentes. Prøv igen senere!",
    noData: "Der blev ikke fundet data inden for det valgte tidsrum",
    fields: {
      name: "Rapportnavn",
      type: "Type",
      tempo: "Interval",
      createdAt: "Oprettet",
      updatedAt: "Opdateret",
      id: "ID",
      refrenceDate: "Referencedag",
      reportContacts: "Rapportkontakter",
      lastSent: "Sidst sendt",
    },

    tempo: {
      desc: {
        0: "Send den daglige rapport fra nu af kl. 03:00",
        1: "Send den ugentlige rapport om mandagen med data fra den foregående uge.",
        2: "Send den månedlige rapport den 1. dag i måneden for den foregående måned.",
      },

      0: "Daglig",
      1: "Ugentlig",
      2: "Månedlig",
    },

    types: {
      regular: "Almindelig",
      trend: "Trend",
    },
  },

  script: {
    name: "Scripts",
    create: "Opret en ny script",
    edit: "Rediger script",
    tagsTitle: "Node ID og sensorer",
    placeHolderContent: ` // Konverter temperaturen til fahrenheit
  var tempToFahrenheit = Data.Read("INSERT-DEVEUI", "INSERT-KEY") * 9/5 + 32;

  // Skriv den nye værdi til en ny nøgle
Data.Write("INSERT-DEVEUI", "INSERT-KEY", tempToFahrenheit);`,
    examples: "Eksempler",
    helpTitle: "Eksempler og hjælp",

    type: {
      0: "Gentagende Script",
      1: "Kaldt Script",
      2: "Udløst Script",
    },

    fields: {
      name: "Navn",
      type: "Type",
      interval: "Interval",
      lastRun: "Sidst kørt tidspunkt",
      enabled: "Aktiveret",
      boundTag: "Udløsende tag",
    },

    validation: {
      intervalRequired: "Dette felt skal udfyldes",
      intervalMinValue: "Den mindste værdi for intervallet er 0",
    },
  },

  user: {
    name: "Bruger",

    email: "Email",
    role: "Rolle",

    userInfo: "Personlige oplysninger",

    loginFailed: "Login mislykkedes",
    signInFailed: "Email/adgangskode matcher ikke. Prøv igen.",
    signInError: "Uventet fejl under login",
    loginSuccessful: "Logget ind",
    updateFailed: "Opdatering mislykkedes",
    edit: "Rediger",
    forgotPassword: "Glemt adgangskode?",

    fields: {
      firstName: "Fornavn",
      lastName: "Efternavn",
      phoneNumber: "Telefonnummer",
      email: "Email",
      password: "Adgangskode",
      newPassword: "Ny adgangskode",
      passwordConfirm: "Bekræft adgangskode",

      phoneNumerHint:
        "Tip: Brug international formatering. F.eks. for et svensk nummer (4575381414)",
    },
  },

  application: {
    name: "Applikation | Applikationer",
    noCompanyError:
      "Du skal først registrere en virksomhed for at oprette applikationer",
    applicationCreated: "Din nye applikation er oprettet",
    choose: "Vælg en applikation",
    create: "Opret en ny applikation",
    validation: {
      nameRequired: "Din applikation skal have et navn",
      nameMax: "Navnet må ikke være længere end 255 tegn",
    },
    fields: {
      id: "ID",
      name: "Navn",
      createdAt: "Oprettet",
      owner: "Ejer",
    },
  },

  alarm: {
    name: "Alarm",
    create: "Tilføj ny alarmregel",
    affirmation: "Bekræft",
    history: "Alarmhistorik",

    activeAlarm: "Alarmadvarsler",

    test: {
      title: "Test af indstillinger",
      settings: "Test email- og sms-indstillinger",
      email: "Email-adresse",
      phonenumber: "Telefonnummer (45....)",
    },

    priority: {
      desc: {
        a: "Høj",
        b: "Medium",
        c: "Lav",
      },
    },

    alertType: {
      desc: {
        a: "Send Email + SMS + Log",
        b: "Send SMS + Log",
        c: "Send Email + Log",
        d: "Send kun til Log",
      },
    },

    fields: {
      id: "ID",
      name: "Navn",
      alertType: "Metode til alarmmeddelelse",
      priority: "Alarmprioritetsniveau",
      alarmDelay: "Forsinkelsestid før alarm udløses",
      autoAcknowledge: "Auto-Bekræftelse",
      active: "Alarm Til/Fra",
      tagDataKey: "Sensor",
      tag: "Node",
      message: "Alarmbesked",
      limitHigh: "Høj grænse",
      limitLow: "Lav grænse",
      contactType: "Kontakttype",
      alarmContacts: "Alarmkontakter",
      activeDays: "Aktive dage",
      limit: "Lav og høj alarmgrænser",
      activeAlarms: "Alarmadvarsler",
      messageSent: "Alarmbesked sendt",
      reset: "Nulstil",
      triggered: "Udløst",
      user: "Nulstillet af",
      value: "Udløsende værdi",
      signalType: "Signaltype",
      digitalTrigger: "Udløs ved",
      triggerHigh: "Høj signalværdi",
      triggerLow: "Lav signalværdi",
      advanced: "Avanceret alarm",
      alarmComment: "Anerkend årsag",
    },

    validation: {
      nameRequired: "Dette felt skal udfyldes",
      max: "Maksimalt antal tegn er 255",
      min: "Mindste antal tegn er ",
    },

    tabs: {
      triggered: "Udløste Alarmer",
      allAlarms: "Alle Alarmer",
      history: "Alarm Historie",
    },
  },

  batteryStatus: {
    levels: "Batteriniveau",
    value: "Værdi",
    lastSeen: "Seneste værdi",
    label: "Batterispænding",
  },

  tagdata: {
    name: "Data",
    amount: "Antal datapunkter indsamlet:",
    notation: "{0}",
    latestactivity: "Seneste aktivitet",
    latestvalue: "Seneste værdi",
    defaultKey: "Standard nøgle",

    fields: {
      id: "ID",
      name: "Navn",
      key: "Sensor",
      value: "Værdi",
      createdAt: "Oprettet",
      label: "Mærkat",
    },
  },

  tag: {
    choose: "Vælg Node",
    search: "Søg Node",
    chooseDecoder: "Vælg en dekoder",
    decoder: "Dekoder",
    name: "Noder",
    amount: "Antal noder:",
    notation: "{0}",
    details: "Sensoroplysninger",
    removeImage: "Slet billede",
    chooseKey: "Vælg nøgle",
    saveKeyAsDefault: "Standard",
    newKey: "Ny Sensor",
    filter: "Filtrer Noder",
    generateId: "Generér ID",

    uploadExplanation:
      "Træk din Csv-fil herover, eller klik her for at uploade Noder",
    uploadExplanationHint: "Du kan downloade en eksempelfil i csv-format her: ",
    exampleLink: "CSV Eksempel",

    createSteps: {
      step1: "Type af Node",
      step2: "Vælg Applikation",
      step3: "Vælg Dekoder",
      step4: "Indstil Nøgler",
      step5: "MQTT",
      finish: "Færdig",
    },

    picker: {
      step1: "Vælg en Node",
      step2: "Vælg en Sensor",
    },

    tagPool: {
      tagPool: "Nodes pulje",
      deveui: "DevEUI",
      createdAt: "Oprettet",
      hide: "Skjul på liste",
      show: "Vis i standardliste",
      hiddenTags: "Skjulte noder",
      take: "Tilføj sensor",
    },

    fields: {
      id: "ID",
      name: "Navn",
      deveui: "DevEUI",
      appKey: "AppKey",
      appEui: "AppEUI",
      applicationId: "Applikation",
      profile: "Profil",
      lat: "Breddegrad",
      long: "Længdegrad",
      latAndLng: "Breddegrad og Længdegrad",
      image: "Billede af sensorinstallation",
      battery: "Batteriniveau",
      activeAlarm: "Alarm",
      activeData: "Aktiv",
      latestValue: "Seneste værdi",
      location: "Placering",
      dataPoints: "Datapunkter/24h",
      type: "Type",
      nodeType: "Nodetype",
    },

    nodeTypes: {
      0: "LORA",
      1: "API",
      2: "MQTT",
      3: "NR",
    },
  },

  license: {
    license: "Licens",
    licensee: "Licensindehaver",
    coveredFeatures: "Dækkede funktioner",
    fields: {
      email: "Email",
      name: "Navn",
      maxCompanies: "Maks. antal virksomheder",
      decoderIncluded: "Dekoder inkluderet",
      whiteLabel: "White-Label",
    },
  },

  validation: {
    required: "{0} skal udfyldes",
    max: "{0} skal være {1} tegn",
    requiredSingle: "Dette felt skal udfyldes",
    toLong: "Dette felt indeholder for mange tegn",
    contryCodeError:
      "Glem ikke, at telefonnummeret skal have landekode (f.eks. 4512345678)",
    passwordMissmatch: "Adgangskoderne stemmer ikke overens",
    deveuiConflict: "Node med denne DevEUI findes allerede på denne server",
  },

  warning: {
    amount: "Aktive alarmer:",
    notation: "{0}",
  },

  mqtt: {
    settingsTabTitle: "MQTT Brokere",
    brokerListTitle: "MQTT Brokere",
    createTitle: "Tilføj en ny MQTT Broker",
    updateTitle: "Opdater MQTT Broker",
    selectBroker: "Vælg en MQTT Broker",
    testBroker: "Test Konfiguration",
    topicCreateTitle: "MQTT Emner",
    topicDescription:
      "De valgte emner vil blive abonneret på via den konfigurerede broker. Emnerne skal være specifikke for enheden, og hvis jokertegnet (+) bruges, skal det være et enkelt niveau jokertegn. Multiniveau jokertegn (#) vil blive omdannet til enkelt niveau jokertegn.",
    topicAdd: "Tilføj et nyt Emne",
    topicAlreadyExists: "Det valgte emne findes allerede",
    createTopicTitle: "Opret et nyt abonnement",

    meta: {
      fields: {
        topic: "Emne",
        publishTopic: "Offentliggør Emne (Valgfrit)",
        publishTopicHint:
          "Kan ikke være det samme som nogen af ​​Abonnér Emnerne",
        parsingTopic: "Dekodertype",
      },
    },

    sslmodes: {
      0: "Ingen",
      1: "CA signerede servercertifikater",
      2: "Selvsignerede servercertifikater",
    },

    versions: {
      0: "3.1.1",
      1: "3.1.0",
    },

    validation: {
      notfound: "Kunne ikke finde Broker",
    },

    fields: {
      status: "Status",
      hostname: "Værtsnavn",
      port: "Port",
      version: "MQTT-version",
      sslmode: "SSL/TLS-tilstand",
      CACertificate: "CA-certifikat",
      ClientCertificate: "Klientcertifikat",
      ClientKey: "Klientnøgle",
      KeyPassPhrase: "Nøglefrase (Valgfri)",
      useAuth: "Brug godkendelse",
      username: "Brugernavn",
      password: "Adgangskode",
      isGlobal: "Er global broker",

      metrics: {
        messages: {
          total: "Samlede beskeder",
          week: "Beskeder denne uge",
          day: "Beskeder i dag",
        },

        publish: {
          total: "Samlet offentliggørelser",
          week: "Offentliggørelser denne uge",
          day: "Offentliggørelser i dag",
        },
      },
    },
  },
};
