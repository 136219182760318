<template>
  <v-text-field
    v-model="number"
    :label="$t('user.fields.phoneNumber')"
    :hint="$t('user.fields.phoneNumerHint')"
    persistent-hint
    prepend-icon="mdi-phone"
    :rules="validate ? phoneNumberRules : []"
  />
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "PhonenumberInput",

  props: {
    value: {
      type: String,
      default: "",
    },

    validate: {
      default: false,
    },
  },

  data() {
    return {
      number: "",
      phoneNumberValid: true,
      countryCallingCode: "",
      phoneNumberRules: [
        (v) => !!v || this.$t("validation.requiredSingle"),
        (v) =>
          (v !== undefined && v[0] !== "0") ||
          this.$t("validation.contryCodeError"),
      ],
    };
  },

  computed: {
    ...mapState("configuration", ["darkmode"]),
  },

  methods: {
    phoneValidation(val) {
      this.countryCallingCode = val.countryCallingCode;
      this.phoneNumberValid = this.number == "" || val.isValid;
    },
  },

  watch: {
    value(val) {
      if (val == null) {
        this.number = "";
        return;
      }

      this.number = val.replace("+", "").replace("-", "").replace(" ", "");
    },

    number(val) {
      if (val == null || val == "") this.number = "";

      this.number.replace("-", "").replace(" ", "");
      this.$emit("input", this.number);
    },
  },
};
</script>

