import ActionRepository from "@/api/repositories/actionRepository";

export const actions = {
  namespaced: true,
  state: {
    action: {},
    actions: [],
    features: {},
    eventTypes: [],
  },

  actions: {
    getAction({ commit, state }, actionId) {
      let result = state.actions.find((a) => a.actionId === actionId);

      commit("SET_ACTION", result ?? {});
    },

    async getActions({ commit }) {
      await ActionRepository.getActions()
        .then((d) => commit("SET_ACTIONS", d.data))
        .catch((e) => {
          commit("SET_ACTIONS", []);
          console.error("Error fetching the actions for company", e);
        });
    },

    async createAction(_, payload) {
      return await ActionRepository.create(payload)
        .then(() => true)
        .catch(() => false);
    },

    async updateAction(_, payload) {
      if (payload?.actionId === undefined) return false;

      return await ActionRepository.update(payload.actionId, payload)
        .then(() => true)
        .catch(() => false);
    },

    async deleteAction(_, actionId) {
      return await ActionRepository.delete(actionId)
        .then(() => true)
        .catch(() => false);
    },

    async getFeatures({ commit }) {
      await ActionRepository.features()
        .then((d) => commit("SET_FEATURES", d.data))
        .catch((e) => {
          commit("SET_FEATURES", {});
          console.error("Error fetching the features for company", e);
        });
    },

    async getEventTypes({ commit }) {
      await ActionRepository.eventTypes()
        .then((d) => commit("SET_EVENT_TYPES", d.data))
        .catch((e) => {
          commit("SET_EVENT_TYPES", []);
          console.error("Error fetching the event types for company", e);
        });
    },

    async resetCooldown(_, actionId) {
      return await ActionRepository.resetCooldown(actionId)
        .then(() => true)
        .catch(() => false);
    },

    async resetResourceCooldown(_, actionResourceId) {
      return await ActionRepository.resetResourceCooldown(actionResourceId)
        .then(() => true)
        .catch(() => false);
    },
  },

  mutations: {
    SET_ACTION(state, payload) {
      state.action = payload;
    },

    SET_ACTIONS(state, payload) {
      state.actions = payload;
    },

    SET_FEATURES(state, payload) {
      state.features = payload;
    },

    SET_EVENT_TYPES(state, payload) {
      state.eventTypes = payload;
    },
  },

  getters: {
    getActionById: (state) => (id) => {
      return state.actions.find((d) => d.actionId === id);
    },

    getFeatureById: (state) => (id) => {
      return state.features[id];
    },
  },
};
